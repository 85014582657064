@font-face {
    font-family: Imported;
    src: url(../fonts/Orbitron-VariableFont_wght.ttf);
  }
  

:root {
    --blue: #1e90ff;
    --white: #ffffff;
    --openbuilder_colour_1: #7F6AFF;
    --openbuilder_colour_2: #171658;
    --openbuilder_colour_background: #ffffff;
    --openbuilder_colour_text_placeholder: #a09292;
  
  }
  

.header {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */
    font-family: Imported;


    
}


.header_left {
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_left img {
    height: 100px;
    width: 100px;
    padding: 5px;
    /* border-radius: 40px; */
}


.header_left h2 {
    font-size: 35px;
    color: var(--openbuilder_colour_2);
}





.header_center {
    display: flex;
    justify-content: center;
    align-items: center;

}


.header_lists {
    display: flex;
    justify-content: center;
    align-items: center;
}


.header_lists li {
    list-style-type: none;
    padding: 10px;
    background-color: red;
}


.header_lists >li>a {
    text-decoration: none;
    color: rgb(255, 255, 255);
}

.header_centerMenu {
    width: 100%;
    height: 100%;
}

.header_centerMenu h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 40%;
    width: 100%;
}

.header_centerMenu button {
    /* padding: 10px;
    margin: 0 10px; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px;
    background-color: var(--openbuilder_colour_2);
    color: var(--openbuilder_colour_background);
    border: none;
    border-radius: 5px;
    border-color: var(--openbuilder_colour_2);
    font-size: 1.2rem;
    cursor: pointer;
    width: 300px;
    font-family: Imported;
    
}


.header_centerMenu:hover > .dropDown_header {
    display: block;
}


.dropDown_header{
    border-radius: 20px;
    display: none;
    position: absolute;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 220px;
    min-height: 120px;
    float: left;
    background-color: #ffffff;
}

.dropDown_header a{
    text-decoration: none;
    padding: 10px;
    font-size: 20px;
    display: flex;
    justify-content: center;
}







.header_right {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 80%;
}

.header_right button {
    /* padding: 10px;
    margin: 0 10px; */


    padding: 10px;
    margin: 10px;
    background-color: var(--openbuilder_colour_2);
    color: var(--openbuilder_colour_background);
    border: none;
    border-radius: 5px;
    border-color: var(--openbuilder_colour_2);
    font-size: 1.2rem;
    cursor: pointer;
    width: 300px;
    font-family: Imported;
}
