@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: Orbitron Variable;
  src: url(./fonts/Orbitron-VariableFont_wght.ttf);
}

* {
  font-family: Orbitron Variable, sans-serif;
}